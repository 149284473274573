import { dom } from "vnet-dom";




export const filtering = () => {
  dom.onClick(dom.body, e => {
    let path = dom.getEventPath(e);
    let filterLink;
    if (filterLink = getFilterLink(path)) {
      e.preventDefault();
      init(filterLink);
    }
  });

  $(dom.body).on('change', e => {
    let target = e.target;
    if (!target.classList.contains('js-mobile-filtering-select')) return;
    let value = target.value;
    let btn = dom.findFirst(`.filter-nav_link[data-filter="${value}"]`);
    if (!btn) return;
    dom.dispatch(btn, 'click');
    $(target).niceSelect('update');
  });
}


const getFilterLink = path => {
  return path.find(item => item && item.classList && item.classList.contains('filter-nav_link'));
}


const init = link => {
  let filter = link.dataset.filter;
  let filterItems = dom.findAll(link.dataset.filterSelector);
  let filterLinks = dom.findAll(link.dataset.linkSelector);
  if (!filter || !filterItems || !filterLinks) return;
  setActiveLink(link, filterLinks);
  setActiveItem(filter, filterItems);

}


const setActiveItem = (filter, filterItems) => {
  filterItems.forEach(item => {
    let itemFilter = item.dataset.filter;
    if (filter === 'all' || itemFilter === filter) {
      dom.addClass(item, 'active');
    } else {
      dom.removeClass(item, 'active');
    }
  });
}


const setActiveLink = (link, filterLinks) => {
  dom.removeClass(filterLinks, 'active');
  dom.addClass(link, 'active');
}
