import { dom } from "vnet-dom";
import { validateInput } from "vnet-dom/DOM/domFormValidate";
import { recaptcha } from "./recaptcha";






export const appendCaptchaToken = (form, isValid) => {
  return new Promise((resolve, reject) => {
    recaptcha(form).then(() => resolve(isValid));
  });
}




export const validateOnBlur = container => {
  let forms = dom.findAll('.validate-form', container);
  if (!forms || !forms.length) return;
  forms.forEach(form => {
    let inputs = dom.findAll('.input', form);
    if (!inputs || !inputs.length) return;
    inputs.forEach(input => {
      input.addEventListener('blur', e => {
        if (validateInput(input)) {
          addSuccesInputClass(input);
        }
      });
    })
  });
}





export const addInputError = (input, msg) => {
  let help = getCreateHelp(input);
  help.innerHTML = msg;
  dom.removeClass(input, 'is-valid');
  dom.addClass(input, 'has-error');
  input.dataset.errorMsg = msg;
  dom.dispatch(input, 'validate-add-error');
}



export const removeInputError = input => {
  removeCompareError(input);
  removeInputErrorHandler(input);
}




const removeCompareError = input => {
  let compare = input.dataset.compare;
  if (!compare) return;
  compare = dom.findFirst(compare);
  if (!compare) return;
  removeInputErrorHandler(compare);
}




const removeInputErrorHandler = input => {
  dom.removeClass(input, 'has-error');
  dom.addClass(input, 'is-valid');
  let help = dom.findFirst('.input-help', input.parentNode);
  if (help) help.innerHTML = '';
  input.removeAttribute('data-error-msg');
  dom.dispatch(input, 'validate-rm-error');
}



const addSuccesInputClass = input => {
  dom.removeClass(input, 'has-error');
  dom.addClass(input, 'is-valid');
}



export const getCreateHelp = input => {
  let help = dom.findFirst('.input-help', input.parentNode);
  if (!help) {
    help = dom.create('span', { className: 'input-help' });
    input.parentNode.appendChild(help);
  }
  return help;
}
