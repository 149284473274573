import { dom } from "vnet-dom";
import { removeInputError } from "./validate";


export const fileInput = container => {
  let items = dom.findAll('.custom-file-input', container);
  if (!items) return;
  items.forEach(item => init(item));
}




const init = item => {
  let input = dom.findFirst('input', item);
  initClearInput(item, input);
  if (!input) return;
  dom.onChange(input, e => {
    let label = getInputLabel(item);
    setInputLabel(input, label);
  });
}

const initClearInput = (item, input) => {
  dom.onClick('.clear-input', e => {
    e.preventDefault();
    e.stopPropagation();
    input.value = '';
    setInputLabel(input, getInputLabel(item));
  }, item);
}



const getInputLabel = item => {
  return dom.findFirst('.file-label', item);
}



const setInputLabel = (input, label) => {
  if (!label || !input) return;
  if (!input.files || !input.files.length) {
    label.innerHTML = label.dataset.default;
    dom.removeClass(input, 'has-file');
    removeInputError(input);
  } else {
    label.innerHTML = input.files[0].name;
    dom.addClass(input, 'has-file');
  }
}

