import { dom } from "vnet-dom";






export const frontSlimLogos = () => {
  let container = dom.findFirst('.js-slim-logos');
  if (!container) return;
  init(container);
  dom.onClick(container, e => {
    e.stopPropagation();
  });
  dom.onClick(dom.body, e => {
    dom.removeClass('.js-slim-logos .slim-logo-dropdown, .js-slim-logos .slim-logo', 'active');
  });
}




const init = container => {
  let breakpoint = parseInt(container.dataset.breakpoint);
  dom.onClick('.slim-logo', e => {
    if (dom.window.innerWidth < breakpoint) return;
    e.preventDefault();
    if (e.currentTarget.classList.contains('active')) {
      dom.removeClass('.slim-logo-dropdown, .slim-logo', 'active', container);
    } else {
      dom.removeClass('.slim-logo-dropdown, .slim-logo', 'active', container);
      dom.addClass(`${e.currentTarget.dataset.target}`, 'active', container);
      dom.addClass(e.currentTarget, 'active', container);
    }
  }, container);
}