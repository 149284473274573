import { dom } from "vnet-dom";
import { preloader } from "./components";
import { dynamicFunctions } from "./";





export const ajaxLoadMore = container => {
  dom.onClick('.js-ajax-load-more', e => {
    e.preventDefault();
    let btn = e.currentTarget;

    let target = btn.dataset.target;
    let url = btn.dataset.ajaxUrl;
    if (!target || !url) return;

    target = dom.findFirst(target);
    if (!target) return;

    let page = btn.dataset.page || 1;

    loadMore(btn, target, page, url);
  }, container);
}





const loadMore = (btn, target, page, url) => {
  dom.addClass(btn, 'disabled');
  dom.ajax({ url, preloader: target, data: { page }, preloaderHTML: preloader }).then(res => parseResponse(res, target, btn));
}




const parseResponse = (res, target, btn) => {
  let div = dom.create('div');
  div.innerHTML = res;
  let container = dom.findFirst('.ajax-response-html', div);
  let children = container.children;

  for (let i = 0; i < children.length; i++) {
    if (!children[i] || !children[i].tagName) continue;
    target.appendChild(children[i].cloneNode(true));
    dynamicFunctions(children[i]);
  }

  let nextPage = container.dataset.nextPage;
  if (!nextPage) return;

  btn.dataset.page = nextPage;
  dom.removeClass(btn, 'disabled');
}