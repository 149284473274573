import { dom } from "vnet-dom";



if (!SVGRectElement.prototype.getTotalLength) {
  SVGRectElement.prototype.getTotalLength = function () {
    let width = this.parentNode.clientWidth,
      radius = width / 2,
      length = 2 * Math.PI * radius;
    return length;
  }
}





export const staticSlick = () => {
  initFrontCoverSlick();
  initHitsSlider();
  initSligneProd();
  initMobileSingleProd();
  initPartenrsSlider();
}

export const dynamicSlick = container => {
  initCountProdsSlider(container);
  aboutSlider(container);
  catSalesSlider(container);
}










const initCountProdsSlider = container => {
  let sections = dom.findAll('.js-prods-count-slider', container);
  if (!sections || !sections.length) return;
  sections.forEach(section => {
    startSliderCountProds(section);
  });
}

const startSliderCountProds = section => {
  let slider = dom.findFirst('.prods-count-slider', section);
  let prevArrow = dom.findFirst('.slick-arrow.prev-arrow', section);
  let nextArrow = dom.findFirst('.slick-arrow.next-arrow', section);
  let countWrap = dom.findFirst('.count-wrap', section);
  if (!slider || !prevArrow || !nextArrow || !countWrap) return;

  let $slider = $(slider);

  $slider.on('init', (event, slick, currentSlide, nextSlide) => {
    setSlickCount(countWrap, slick, currentSlide, sliderCountSlidesToShow());
  });

  $slider.on('afterChange', (event, slick, currentSlide, nextSlide) => {
    setSlickCount(countWrap, slick, currentSlide, sliderCountSlidesToShow());
  });

  if(!$slider.hasClass('slick-initialized')) {
      $slider.slick({
        slidesToShow: 4,
        infinite: false,
        slidesToScroll: 4,
        touchThreshold: 30,
        prevArrow: prevArrow,
        nextArrow: nextArrow,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      });
  }
}


const sliderCountSlidesToShow = () => {
  let width = dom.window.innerWidth;
  if (width < 576) return 1;
  if (width < 992) return 2;
  if (width < 1024) return 3;
  return 4;
}




const setSlickCount = (countWrap, slick, currentSlide, slidesToShow) => {
  let current = currentSlide ? currentSlide : 0;
  let visible = slidesToShow ? current + slidesToShow : current + sliderCountSlidesToShow();
  let currentPage = Math.ceil(visible / slidesToShow);
  visible = visible > slick.slideCount ? slick.slideCount : visible;

  let pages = Math.ceil(slick.slideCount / slidesToShow);
  countWrap.innerHTML = `${formatTwoNumber(currentPage)}/${formatTwoNumber(pages)}`;

  if(slick.slideCount <= slidesToShow) {
    $(countWrap).closest('.slider-controls').css('display', 'none');
  }
  else {
    $(countWrap).closest('.slider-controls').css('display', '');
  }
}






// const sliderCountSlidesToScroll = () => {
//   let width = dom.window.innerWidth;
//   if (width > 1024) return 4;
//   if (width > 992) return 3;
//   if (width > 768) return 2;
//   return 1;
// }


// const calcTotalSlidesCount = (totalSlides, slidesToShow, slidesToScroll) => {
//   console.log(totalSlides);
//   slidesToShow = slidesToShow - slidesToScroll;
//   return Math.ceil(totalSlides - slidesToShow > 0 ? totalSlides - slidesToShow : 1);
// }


const formatTwoNumber = num => {
  if (num < 10) return '0' + num.toString();
  return num.toString();
}







const initSligneProd = () => {
  let slider = dom.findFirst('.single-prod-slider');
  if (!slider) return;
  let $slider = $(slider);

  let navSlider = dom.findFirst('.single-prod-slider-nav');
  if (!navSlider) return;
  let $navSlider = $(navSlider);

  $slider.slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    touchThreshold: 50,
    asNavFor: $navSlider
  });
  $navSlider.slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: $slider,
    arrows: false,
    vertical: true,
    verticalSwiping: true,
    dots: true,
    focusOnSelect: true,
    touchThreshold: 50
  });

}




const initFrontCoverSlick = () => {
  let slider = dom.findFirst('.front-cover-slider');
  if (!slider) return;
  let $slider = $(slider);

  let appendDots = dom.findFirst('.front-cover-slider-wrap .dots-container');

  $slider.on('init', (event, slick, currentSlide, nextSlide) => {
    let current = dom.findFirst('.slick-active button');
    if(slick.slideCount > 1) {
      dom.addClass(current, 'animation-start');
      let sets = { speed: 3000 };
      animateSlimDot(current, sets);
    }
    else {
      appendDots.remove();
    }
  });

  $slider.on('afterChange', e => {
    clearSlimDots(appendDots);
    let current = dom.findFirst('.slick-active button');
    dom.addClass(current, 'animation-start');
    let sets = { speed: 3000 };
    animateSlimDot(current, sets);
  });

  $slider.slick({
    dots: true,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: false,
    lazyLoad: 'progressive',
    prevArrow: '.front-cover-slider-wrap .prev-arrow',
    nextArrow: '.front-cover-slider-wrap .next-arrow',
    appendDots: appendDots
  });
}


const clearSlimDots = appendDots => {
  let dots = dom.findAll('.slick-dots button', appendDots);
  dots.forEach(dot => {
    dom.removeClass(dot, 'animation-start');
    dot.removeAttribute('style');
  });
}



const animateSlimDot = (dot, sets) => {
  if (!dot.classList.contains('animation-start')) return;

  dom.window.requestAnimationFrame((time) => {
    if (!sets.startTime) sets.startTime = time;

    if (dot.classList.contains('pause')) {
      time = sets.startTime;
      animateSlimDot(dot, sets);
    }

    let progress = (time - sets.startTime) / sets.speed;
    progress = progress < 1 ? progress : 1;

    if (!dot.classList.contains('animation-start')) return;
    dom.addCss(dot, { background: `linear-gradient(to right, #fff 0%, #fff ${progress * 100}%, rgba(255,255,255,.3) ${progress * 100 + 1}%, rgba(255,255,255,.3) 100%)` });

    if (progress < 1) {
      animateSlimDot(dot, sets);
    }
  });
}




const initHitsSlider = () => {
  let items = dom.findAll('.hits-slider-wrap');
  if (!items || !items.length) return;

  items.forEach(item => {
    let slider = dom.findFirst('.hits-slider', item);
    if (!slider) return;

    let prevArrow = dom.findFirst('.prev-arrow', item);
    let nextArrow = dom.findFirst('.next-arrow', item);
    let appndDots = dom.findFirst('.dots-wrap', item);
    let $slider = $(slider);

    let args = setSlickAutoplay($slider, appndDots);

    args.prevArrow = prevArrow;
    args.nextArrow = nextArrow;

    $slider.slick(args);
  });

}






const aboutSlider = container => {
  let items = dom.findAll('.about-slick-slider', container);
  if (!items) return;
  items.forEach(item => {
    initAboutSlider(item);
  });
}




const initAboutSlider = item => {
  let slider = dom.findFirst('.slick-slider', item);
  if (!slider) return;
  let $slider = $(slider);
  let prevArrow = dom.findFirst('.prev-arrow', item);
  let nextArrow = dom.findFirst('.next-arrow', item);
  let countWrap = dom.findFirst('.count-wrap', item);

  $slider.on('init', (event, slick, currentSlide, nextSlide) => {
    setSlickCount(countWrap, slick, currentSlide, getAboutSlidesToShow());
  });

  $slider.on('afterChange', (event, slick, currentSlide, nextSlide) => {
    setSlickCount(countWrap, slick, currentSlide, getAboutSlidesToShow());
  });

  let slickSets = {
    infinite: false,
    slidesToShow: 2.1,
    slidesToScroll: 2,
    prevArrow: prevArrow,
    nextArrow: nextArrow,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1
        }
      }
    ]
  };
  initAboutSliderFancybox(slider);
  // let fancyElements = dom.findAll('.about-fancybox', slider);
  // if (fancyElements) {
  //   $(fancyElements).fancybox({
  //     afterClose: () => {
  //       // $slider.slick('destroy');
  //       // $slider.slick(slickSets);
  //     }
  //   });
  // }
  $slider.slick(slickSets);
}


const initAboutSliderFancybox = (slider) => {
  let items = dom.findAll('.about-fancybox', slider);
  if (!items) return;
  let images = items.map(item => {
    return {
      src: item.getAttribute('href')
    }
  });
  dom.onClick(items, e => {
    e.preventDefault();
    $.fancybox.open(images);
  });
}



const getAboutSlidesToShow = () => {
  let width = dom.window.innerWidth;
  if (width > 992) return 2;
  return 1;
}


const getSlidesToShowAboutSlider = () => {
  let width = dom.window.innerWidth;
  if (width > 992) return 2.1;
  return 1.1;
}





const catSalesSlider = container => {
  let items = dom.findAll('.cat-sales-slider', container);
  if (!items) return;
  items.forEach(item => {
    initCatSalesSlider(item);
  });
}



const initCatSalesSlider = item => {
  let slider = dom.findFirst('.slick-slider', item);
  let appendDots = dom.findFirst('.dots-wrap', item);
  let $slider = $(slider);
  let args = setSlickAutoplay($slider, appendDots);

  args.nextArrow = '.cat-sales-slider .next-arrow';
  args.prevArrow = '.cat-sales-slider .prev-arrow';
  $slider.slick(args);
}




const initMobileSingleProd = () => {
  let slider = dom.findFirst('.single-prod-mobile-slick .slick-slider');
  if (!slider) return;
  let $slider = $(slider);
  $slider.slick({
    dots: true,
    arrows: false,
    appendDots: '.single-prod-mobile-slick .append-dots'
  })
}







const initPartenrsSlider = () => {
  let section = dom.findFirst('.js-section-partners');
  if (!section) return;
  let slider = dom.findFirst('.slick-slider', section);

  maybeLoadPartenrsSlider(slider, section);

  dom.onWindowScroll(e => {
    maybeLoadPartenrsSlider(slider, section);
  });
}


const maybeLoadPartenrsSlider = (slider, section) => {
  if (slider.classList.contains('is-inited') || !dom.isInViewport(slider, '30vh')) return;
  dom.addClass(slider, 'is-inited');

  let $slider = $(slider);

  let slides = dom.findAll('.slick-item', slider);
  let totalSlides = slides.length;

  let prevArrow = dom.findFirst('.prev-arrow', section);
  let countWrap = dom.findFirst('.count-wrap', section);
  let nextArrow = dom.findFirst('.next-arrow', section);


  $slider.on('init', (event, slick, currentSlide, nextSlide) => {
    setSlickCount(countWrap, slick, currentSlide, getSlidesToShowPartnersSlider());
  });

  $slider.on('afterChange', (event, slick, currentSlide, nextSlide) => {
    setSlickCount(countWrap, slick, currentSlide, getSlidesToShowPartnersSlider());
  });

  $slider.slick({
    slidesToShow: 6,
    slidesToScroll: 6,
    infinite: false,
    adaptiveHeight: false,
    nextArrow: nextArrow,
    prevArrow: prevArrow,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ]
  });
}


const getSlidesToShowPartnersSlider = () => {
  let width = dom.window.innerWidth;
  if (width < 576) return 2;
  if (width < 768) return 3;
  if (width < 992) return 4;
  return 6;
}






const setSlickAutoplay = ($slider, appendDots, type = 1) => {

  let speed = 6000;

  let args = {
    dots: true,
    appendDots: appendDots,
    autoplay: true,
    autoplaySpeed: speed,
    pauseOnHover: false
  }

  if (type === 1) {
    args.customPaging = () => {
      return `
      <div class="autofill-bullet">
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none">
          <rect class="path-stroke" x="1" y="1" width="13" height="13" rx="6.5" fill="white" stroke="" stroke-width="2"/>
          <rect x="5" y="5" width="5" height="5" rx="2.5" fill="#999999"/>
        </svg>
      </div>
      `;
    }
  }

  let sets = { pause: false, speed: speed };


  $slider.on('init', (event, slick, currentSlide, nextSlide) => {
    if(slick.slideCount > 1) {
      setAutobulletsInitialState(appendDots);
      sets.path = dom.findFirst('.slick-active .path-stroke', appendDots);
      dom.addClass(sets.path, 'path-animated');
      animatePathStroke(sets);
    }
    else {
      appendDots.remove();
    }
  });


  $slider.on('afterChange', (event, slick, currentSlide) => {
    setAutobulletsInitialState(appendDots);
    sets = { pause: false, speed: speed };
    sets.path = dom.findFirst('.slick-active .path-stroke', appendDots);
    dom.addClass(sets.path, 'path-animated');
    animatePathStroke(sets);
  });


  if (args.pauseOnHover) {
    $slider.on('mouseenter', e => {
      dom.addClass('.path-stroke', 'pause', appendDots);
    });

    $slider.on('mouseleave', e => {
      dom.removeClass('.path-stroke', 'pause', appendDots);
    });
  }



  return args;
}


const setAutobulletsInitialState = appendDots => {
  let items = dom.findAll('.path-stroke', appendDots);
  items.forEach(path => svgStrokeSetInitialState(path));
}

const svgStrokeSetInitialState = path => {
  dom.removeClass(path, 'path-animated');
  let length = path.getTotalLength();
  dom.addCss(path, { strokeDasharray: `${length} ${length}`, strokeDashoffset: length });
}





const animatePathStroke = (sets) => {
  if (!sets.length) sets.length = sets.path.getTotalLength();
  if (sets.nextValue === 0 || !sets.path.classList.contains('path-animated')) return;


  dom.window.requestAnimationFrame((time) => {
    if (!sets.startTime) sets.startTime = time;

    if (sets.path.classList.contains('pause')) {
      sets.startTime = time;
      animatePathStroke(sets);
      return;
    }

    let progress = (time - sets.startTime) / sets.speed;
    progress = progress > 1 ? 1 : progress;

    sets.nextValue = sets.length - (progress * sets.length);
    sets.nextValue = sets.nextValue > 0 ? sets.nextValue : 0;

    sets.path.style.strokeDashoffset = sets.nextValue;

    if (progress < 1) {
      animatePathStroke(sets);
    }
  });
}
