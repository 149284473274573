import { dom } from "vnet-dom";


export const resetForm = container => {
  dom.onClick('.js-reset-form', e => {
    e.preventDefault();
    reset(e.currentTarget);
  }, container);
}


const reset = btn => {
  let form = btn.dataset.form;
  if (!form) return;
  form = dom.findFirst(form);
  if (!form) return;
  form.reset();
  let inputs = dom.findAll('input', form);
  inputs.forEach(input => {
    input.value = '';
  });
  dom.removeClass('.has-value[value=""]', 'has-value', form);
  dom.removeClass('.has-error', 'has-error', form);
  dom.removeClass('.is-valid', 'is-valid', form);
}
