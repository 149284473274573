import { dom } from "vnet-dom";
import { preloader } from "../components";
import { dynamicFunctions } from "../";
import { mobileFilter } from "./mobileFilter";


export const filter = () => {
  dynamicFilterFunctions();
  initSearch();
  initPopState();
  setCountPpi();
}



const dynamicFilterFunctions = container => {
  let filter = dom.findFirst('.js-filter-form', container);
  if (!filter) return;
  //initFilterSubmit(filter);
  initPpi(filter, container);
  initResetFilter(filter, container);
  initPagination(filter, container);
  initOrderBy(filter, container);
  mobileFilter();
}




const initSearch = () => {
  let filter = dom.findFirst('.js-filter-form');
  let form = dom.findFirst('.js-search-form');
  if (!form || !filter) return;
  form.addEventListener('submit', e => {
    e.preventDefault();
    let searchInput = dom.findFirst('.js-search-input', form);
    let filterInput = dom.findFirst('.js-search-input', filter);
    if (!searchInput || !filterInput) return;
    filterInput.value = searchInput.value;
    dom.dispatch(filter, 'submit');
    return false;
  });
}




const initPopState = () => {
  dom.window.addEventListener('popstate', e => {
    if (!e.state || !e.state.html) return;
    let container = setResultHTML(e.state.html);
    dynamicFunctions(container);
    dynamicFilterFunctions(container);
    scrollToContainer(container);
  });
}




const initResetFilter = (filter, container) => {
  dom.onClick('.reset-filter-btn', e => {
    e.preventDefault();
    resetFilterInputs(filter);
    dom.dispatch(filter, 'submit');
  }, container);
}





const setCountPpi = () => {
  let ppis = dom.findAll('.js-ppi-container .ppi');
  let count = dom.findFirst('.js-count-ppi');
  if (!count) return;
  if (!ppis) {
    count.innerHTML = '';
    dom.removeClass(count, 'active');
  } else {
    count.innerHTML = `(${ppis.length})`;
    dom.addClass('.js-count-ppi', 'active');
  }
}



const resetFilterInputs = filter => {
  let inputs = dom.findAll('select,input', filter);
  if (!inputs) return;
  inputs.forEach(input => {
    if (input.type === 'checkbox') {
      input.checked = false;
      return;
    }
    if (input.classList.contains('no-reset')) {
      return;
    }
    input.value = input.dataset.defaultValue;
  });
}



const initPpi = (filter, container) => {
  dom.onClick('.rm-ppi', e => {
    e.preventDefault();
    let btn = e.currentTarget;
    btn.dataset.input.split(',').map(item => item.replace(/[\s]+/g, '')).filter(item => item).forEach(name => {
      let input = dom.findFirst(`input[name=${name}]`, filter);
      if (!input) return;
      if (input.type === 'checkbox') {
        input.checked = false;
        return;
      }
      input.value = input.dataset.defaultValue || '';
    });
    dom.dispatch(filter, 'submit');
  }, container);
}







const initFilterSubmit = filter => {
  filter.addEventListener('submit', e => {
    e.preventDefault();
    let data = getFilterData(filter);
    let query = buildHttpQuery(data);
    if (dom.window.location.search === query) return;
    if (!dom.window.location.search && query === dom.window.location.pathname) return;
    sendFilterData(filter, data).then(res => setFilterQueryResults(res, query, filter));
  });
}




const sendFilterData = (filter, data) => {
  return new Promise((resolve, reject) => {
    let url = filter.dataset.ajaxUrl;
    dom.ajax({
      url: url,
      preloader: dom.body,
      preloaderHTML: preloader,
      minTimeResponse: 500,
      data: data
    }).then(res => resolve(res));
  });
}





const setFilterQueryResults = (res, query, filter) => {
  let container = setResultHTML(res);
  if (container) {
    dynamicFunctions(container);
    dynamicFilterFunctions(container);
  }
  setHistory(res, query);
  scrollToContainer(container);
  dom.removeClass(dom.body, 'filter-is-open compensate-for-scrollbar');
}



const setResultHTML = res => {
  res = dom.strToDom(res);
  let container = dom.findFirst('.js-ajax-refresh');
  if (!container) return;
  container.innerHTML = '';
  container.appendChild(res);
  return container;
}




const setHistory = (html, query) => {
  dom.window.history.pushState({ html }, dom.document.title, query);
}





const getFilterData = filter => {
  let inputs = dom.findAll('input, select', filter);
  let res = {};
  inputs.forEach(input => {
    if (!input.name) return;

    if (input.type === 'checkbox') {
      if (input.checked) {
        res[input.name] = 'on';
      }
      return;
    }

    if (input.value !== input.dataset.defaultValue) {
      res[input.name] = input.value;
    }
  });
  return res;
}


const buildHttpQuery = data => {
  let res = false;
  Object.keys(data).forEach(key => {
    res = res ? res + '&' : '?';
    res += `${key}=${data[key]}`;
  });
  return res ? res : dom.window.location.pathname;
}



const initPagination = (filter, container) => {
  dom.onClick('.js-pagination', e => {
    e.preventDefault();
    let btn = getEventPaginationBtn(e);
    if (!btn) return;
    let page = btn.dataset.page;
    let input = dom.findFirst(`.js-page-input`, filter);
    if (input.value === page) return;
    input.value = page;
    dom.dispatch(filter, 'submit');
  }, container);
}

const getEventPaginationBtn = e => {
  let path = dom.getEventPath(e);
  return path.find(item => item && item.classList && item.classList.contains('js-pag-btn') && !item.classList.contains('current'));
}




const initOrderBy = (filter, container) => {
  let select = dom.findFirst('#orderBySelect', container);
  if (!select) return;
  $(select).on('change', e => {
    let input = dom.findFirst('.js-orderby-input', filter);
    if (!input) return;
    input.value = select.value;
    dom.dispatch(filter, 'submit');
  });
}





const scrollToContainer = container => {
  let top = container.getBoundingClientRect().top + dom.window.pageYOffset;
  setTimeout(() => {
    dom.window.scrollTo(0, top);
  }, 0);
}