import { dom } from "vnet-dom";
import { preloader } from "../components";
import { displayResponseModal, displayModal } from "../ajaxModal";



export const viewOrderDtails = container => {
  dom.onClick('.js-order-details', e => {
    e.preventDefault();
    let link = e.currentTarget;
    init(link);
  }, container);
}



const init = link => {
  let id = link.dataset.id;
  let existModal = dom.findFirst(`#order_${id}`)
  if (existModal) {
    displayModal(existModal);
  } else {
    dom.ajax({
      preloader: dom.body,
      preloaderHTML: preloader,
      url: `${back_dates.ajax_url}?ajax_action=get_order_details`,
      minTimeResponse: 500,
      data: { id }
    }).then(res => displayResponseModal(res));
  }
}