import { dom } from "vnet-dom";
import { preloader, createAlertModal, dispalyConfirmModal } from "../components";
import { parseServerResponse } from "../parseServerResponse";
import { roundPrice, priceFormat } from "./";



export const ajaxCart = () => {
  let sets = {};
  sets.timerUpdate = false;

  dom.onClick(dom.body, e => {
    let path = dom.getEventPath(e);
    parseClick(path, e, sets);
  });


  dom.body.addEventListener('change', e => {
    initChangeQuantity(e, sets);
  });
  dom.body.addEventListener('custom-number-change', e => {
    initChangeQuantity(e, sets);
  });

  checkCartConfirmAlert();
}




const initChangeQuantity = (e, sets) => {

  let input = e.target;
  if (!input.classList.contains('js-cart-prod-quantity')) return;

  clearTimeout(sets.timerUpdate);

  let quantity = input.value;
  let id = input.dataset.id;
  let type = input.dataset.type;

  sets.timerUpdate = setTimeout(() => {
    dom.ajax({ url: `${back_dates.ajax_url}?ajax_action=change_cart_quantity`, data: { id, quantity, type } })
      .then(res => updateCartPage(res));
  }, 300);

}




const parseClick = (path, e, sets) => {
  // let addBtn;
  // if (addBtn = getAddToCartBtn(path)) {
  //   e.preventDefault();
  //   addToCart(addBtn);
  //   return;
  // }

  let orderBtn;
  if (orderBtn = getOrderBtn(path)) {
    e.preventDefault();
    completeOrder(sets, orderBtn);
    return;
  }

  let rmBtn;
  if (rmBtn = getRmBtn(path)) {
    e.preventDefault();
    removeProduct(rmBtn);
    return;
  }
}





const completeOrder = (sets, orderBtn) => {
  clearTimeout(sets.timerUpdate);
  let url = `${back_dates.ajax_url}?ajax_action=complete_order`;
  if (orderBtn.dataset.type === 'outstock') {
    url += '&type=outstock';
  } else {
    url += '&type=instock';
  }
  dom.ajax({ url: url, preloader: '.section-cart', preloaderHTML: preloader, minTimeResponse: 500 })
    .then(res => updateCartPage(res));
}




const removeProduct = btn => {
  let id = btn.dataset.id;
  let rowId = btn.dataset.container.replace(/[#]+/, "");
  let type = btn.dataset.type;

  markSelectedCartRow([rowId]);
  dispalyConfirmModal({
    html: 'Удалит товар ?',
    onConfirm: () => realRemoveProduct(id, type, rowId),
    beforeClose: () => unMarkSelectedCartRow([rowId])
  });
}




const realRemoveProduct = (id, type, rowId) => {
  dom.ajax({
    url: `${back_dates.ajax_url}?ajax_action=rm_from_card`,
    data: { id, type },
    preloader: '.section-cart',
    preloaderHTML: preloader,
    minTimeResponse: 500
  }).then(res => {
    res = parseServerResponse(res);
    if (res.type === 'success') {
      let cart = res.cart;
      if (cart.quantity === 0) return;
      rmCartRowHTML(rowId);
      updateTopCart(cart);
      updateCartPageTotalHTML(cart);
    }
  });
}




const rmCartRowHTML = id => {
  let row = getCartRowById(id);
  if (!row) return;
  row.parentNode.removeChild(row);
}





export const addToCart = container => {
  dom.onClick('.add-cart-btn', e => {
    e.preventDefault();
    initAddToCart(e.currentTarget);
  }, container);
}





const initAddToCart = btn => {
  let id = btn.dataset.id;
  let quantityInput = btn.dataset.quantityInput;
  quantityInput = quantityInput ? dom.findFirst(quantityInput) : false;
  let quantity = quantityInput ? parseInt(quantityInput.value) : 1;
  let container = getBtnContainer(btn);

  dom.ajax({ url: `${back_dates.ajax_url}?ajax_action=add_to_cart`, data: { id, quantity }, preloader: container, preloaderHTML: preloader, minTimeResponse: 500 })
    .then(res => {
      res = parseServerResponse(res);
      if (res.type === 'success') {
        updateTopCart(res.cart);
        dom.addClass(container, 'in-cart');
      }
    });
}





const updateTopCart = cart => {
  let topCart = dom.findFirst('.top-cart');
  if (!topCart) return;
  dom.removeClass(topCart, 'is-empty');
  let count = dom.findFirst('.count', topCart);
  if (!count) return;
  count.innerHTML = cart.quantity;
}




const updateCartPage = res => {
  res = parseServerResponse(res);
  updateCartPageTotalHTML(res.cart);
  if (res.cartIsChange) {
    alertCartIsChange(res);
  }
  if (res.changedProducts) {
    markSelectedCartRow(res.changedProducts);
  }
}



const updateCartPageTotalHTML = cart => {
  let total = parseFloat(cart.total);

  let nds = calcNds(total);

  let totalHTML = dom.findFirst('.js-total-price');
  if (totalHTML) totalHTML.innerHTML = priceFormat(total);

  let ndsHTML = dom.findFirst('.js-nds-price');
  if (ndsHTML) ndsHTML.innerHTML = priceFormat(nds);

  let totalNdsHTML = dom.findFirst('.js-total-nds-price');
  if (totalNdsHTML) totalNdsHTML.innerHTML = priceFormat(total + nds);
}




const markSelectedCartRow = ids => {
  ids.forEach(id => {
    let row = getCartRowById(id);
    if (!row) return;
    dom.addClass(row, 'selected-row');
  });
}


const unMarkSelectedCartRow = ids => {
  ids.forEach(id => {
    let row = getCartRowById(id);
    if (!row) return;
    dom.removeClass(row, 'selected-row');
  });
}


const getCartRowById = id => {
  return dom.findFirst(`#${id}`);
  // let selector = `.cart-row[data-id="${id}"]`;
  // return dom.findFirst(selector);
}



const alertCartIsChange = res => {
  let modal = createAlertModal(res.alertHTML);
  dom.window.sessionStorage.setItem('cartConfirmHTML', res.confirmHTML);
  dom.addClass(dom.body, 'fancy-transparent-bg');
  $.fancybox.open(modal, {
    touch: false,
    afterClose: () => {
      dom.removeClass(dom.body, 'fancy-transparent-bg');
      dom.window.location.hash = 'outStockOrder';
      dom.window.location.reload(true);
    }
  });
}




const getBtnContainer = btn => {
  let container = btn.dataset.container;
  if (!container) return dom.body;
  container = dom.findFirst(container);
  if (!container) return dom.body;
  return container;
}




const getAddToCartBtn = path => {
  return path.find(item => item && item.classList && item.classList.contains('add-cart-btn'));
}


const getOrderBtn = path => {
  return path.find(item => item && item.classList && item.classList.contains('js-order-btn'));
}


const getRmBtn = path => {
  return path.find(item => item && item.classList && item.classList.contains('rm-from-cart'));
}


const calcNds = price => {

  let nds = back_dates.ndsPercent;
  if (!nds) {
    console.error("Missing ndsPercent key in back_dates object!");
    return false;
  }
  return roundPrice(parseFloat(nds) * parseFloat(price) / 100);
}



const checkCartConfirmAlert = () => {
  let alert = dom.window.sessionStorage.getItem('cartConfirmHTML');
  if (!alert) return;
  dom.window.sessionStorage.removeItem('cartConfirmHTML');
  let modal = createAlertModal(alert);
  dom.addClass(dom.body, 'fancy-transparent-bg');
  $.fancybox.open(modal, { touch: false, afterClose: () => dom.removeClass(dom.body, 'fancy-transparent-bg') });
}
