import { dom } from "vnet-dom";



export const showPassword = container => {
  dom.onClick('.input-show-password', e => {
    e.preventDefault();
    let btn = e.currentTarget;
    let input = btn.parentNode.querySelector('.input');
    if (!input) return;
    togglePassword(input, btn);
  }, container);
}



const togglePassword = (input, btn) => {
  let type = input.getAttribute('type');
  if (type === 'text') {
    input.type = 'password';
    dom.removeClass(btn, 'pass-visible');
  } else {
    input.type = 'text';
    dom.addClass(btn, 'pass-visible');
  }
}