import { dom } from "vnet-dom";





export const cartPage = () => {
  // changeBtnTextOnTabChange();
}



// const changeBtnTextOnTabChange = () => {
//   let tabsWrap = dom.findFirst('.js-cart-page-tabs');
//   let btn = dom.findFirst('.js-order-btn .text');
//   if (!tabsWrap || !btn) return;


//   tabsWrap.addEventListener('dom-tab-change', e => {
//     let current = dom.findFirst(e.detail.current);
//     if (!current) return;
//     let txt = current.dataset.orderBtnText;
//     if (!txt) return;
//     btn.innerHTML = txt;
//   });
// }