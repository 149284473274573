import { dom } from "vnet-dom";




export const alertOnLoad = () => {
  let modals = dom.findAll('.js-alert-on-load');
  if (!modals) return;

  modals.forEach(modal => {
    $.fancybox.open(modal, {
      touch: false,
      beforeShow: () => dom.addClass(dom.body, 'fancy-transparent-bg'),
      afterClose: () => {
        dom.removeClass(dom.body, 'fancy-transparent-bg');
        if (!modal.dataset.redirect) return;
        dom.window.location = modal.dataset.redirect;
      }
    });
  });
}