import { dom } from "vnet-dom";
import { formatInputNumValue } from "./inputNumber";



export const rangeInput = container => {
  let items = dom.findAll('.range-input', container);
  if (!items || !items.length) return;
  items.forEach(item => init(item));
}




const init = item => {
  let fromInput = dom.findFirst('.from-input', item);
  let toInput = dom.findFirst('.to-input', item);

  let rangeInput = dom.findFirst('.range-input', item);
  let $rangeInput = $(rangeInput);

  if (rangeInput) {
    $rangeInput.ionRangeSlider({
      onChange: data => setInputsValue(data, fromInput, toInput)
    });
  }

  let range = rangeInput ? $rangeInput.data('ionRangeSlider') : false;

  item.addEventListener('change', e => onInputValueChange(e, fromInput, toInput, range));
}




const setInputsValue = (data, fromInput, toInput) => {
  fromInput.value = data.from;
  toInput.value = data.to;
}




const onInputValueChange = (e, fromInput, toInput, range) => {
  let input = e.target;
  if (!input.tagName || input.tagName !== 'INPUT') return;

  let isFrom = input.classList.contains('from-input');

  let sets = {};
  if (isFrom) {
    sets.max = parseFloat(toInput.value);
  } else {
    sets.min = parseFloat(fromInput.value);
  }

  let val = formatInputNumValue(input.value, input, sets);
  input.value = val;

  if (!range) return;

  if (isFrom) {
    range.update({ from: val });
  } else {
    range.update({ to: val });
  }

}



