import { dom } from "vnet-dom";




export const mobileMenu = () => {
  dom.onClick('.js-open-mobile-menu', e => {
    e.preventDefault();
    if (dom.body.classList.contains('mobile-menu-is-open')) {
      closeMenu();
    } else {
      openMenu();
    }
  });
}



const closeMenu = () => {
  dom.removeClass(dom.body, 'mobile-menu-is-open compensate-for-scrollbar');
}


const openMenu = () => {
  dom.addClass(dom.body, 'mobile-menu-is-open compensate-for-scrollbar');
}