import { dom } from "vnet-dom";
import { ajaxCart, addToCart } from "./ajaxCart";
import { viewOrderDtails } from "./viewOrderDtails";
import { filter } from "./filter";
// import { discoverPrice } from "./discoverPrice";
import { cartPage } from "./cartPage";




export const staticShopFunctions = () => {
  initCalcTotalOnQuantityChange();
  ajaxCart();
  filter();
  cartPage();
}



export const dynamicShopFunctions = container => {
  viewOrderDtails(container);
  initSetGrid(container);
  addToCart(container);
  // discoverPrice(container);
}






const initSetGrid = container => {
  dom.onClick('.set-grid-col, .set-grid-row', e => {
    e.preventDefault();
    let target = e.currentTarget;
    if (target.classList.contains('set-grid-row')) {
      dom.addClass(dom.body, 'grid-row');
    } else {
      dom.removeClass(dom.body, 'grid-row');
    }
  }, container);
}




const initCalcTotalOnQuantityChange = () => {
  dom.body.addEventListener('custom-number-change', e => {
    let input = e.target;

    if (!input.classList.contains('prod-quantity')) return;
    let target = input.dataset.target;
    if (!target) return;
    target = dom.findFirst(target);
    if (!target) return;

    let price = roundPrice(parseFloat(input.dataset.price) * parseInt(input.value));
    target.innerHTML = priceFormat(price);
  });
}





export const roundPrice = price => {
  price = parseFloat(price);
  let res = Math.round((price + Number.EPSILON) * 100) / 100;
  return res;
}




export const priceFormat = price => {
  price = roundPrice(price);
  let [integer, decimal] = price.toString().split('.');

  if (!decimal) {
    decimal = '00';
  } else {
    if (decimal.length < 2) {
      decimal += '0';
    }
  }

  integer = parseInt(integer).toLocaleString('ru');
  return `${integer}.${decimal}`;
}
