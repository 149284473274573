/**
 * 
 * @DEPRECATED
 * 
 * 
 * Открывем галерею fancybox
 * на странице товара
 * 
 */

import { dom } from "vnet-dom";



export const singleProdGallery = () => {
  return;

  let sliders = dom.findAll('.single-prod-slider, .single-prod-mobile-slick');
  if (!sliders) return;
  sliders.forEach(slider => initGallery(slider));
}





const initGallery = slider => {
  dom.onClick('.single-prod-gallery', e => {
    e.preventDefault();
    openGallery(slider, e.currentTarget);
  }, slider);
}





const openGallery = (slider, btn) => {
  if (btn.tagName !== 'A') return;
  let items = getGalleryItems(slider);
}




const getGalleryItems = slider => {
  let res = [];
  let items = dom.findAll('.single-prod-gallery', slider);
  if (!items) return false;

  items.forEach(item => {

  })
}