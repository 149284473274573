import { dom } from "vnet-dom";



export const inputNumber = container => {
  let numInputs = dom.findAll('.custom-input-number', container);
  if (!numInputs && !numInputs.length) return;

  initCustomInputNumber(numInputs);
}





const initCustomInputNumber = items => {
  items.forEach(item => {
    setCustomInputNumClick(item);
    setCustomInputChange(item);
  });
}





const setCustomInputChange = (item) => {
  let input = dom.findFirst('input', item);
  if (!input) return;
  input.addEventListener('change', e => {
    input.value = formatInputNumValue(input.value, input);
    dom.dispatch(input, 'custom-number-change');
  });
}







const setCustomInputNumClick = item => {
  item.addEventListener('click', e => {
    e.preventDefault();
    let btn = e.target;
    if (!btn.tagName || !btn.classList || !btn.classList.contains('input-num-control')) return;
    let input = dom.findFirst('input', item);
    if (!input) return;

    let val = parseInt(input.value);

    if (btn.classList.contains('minus-input-number')) {
      val = formatInputNumValue(val - 1, input);
      input.value = val;
      dom.dispatch(input, 'custom-number-change');
      return;
    }

    if (btn.classList.contains('plus-input-number')) {
      val = formatInputNumValue(val + 1, input);
      input.value = val;
      dom.dispatch(input, 'custom-number-change');
      return;
    }
  });
}








export const formatInputNumValue = (val, input, sets = {}) => {
  val = val.toString();

  let isFloat = parseInt(input.dataset.float);

  let min = input.hasAttribute('min') ? convertToNum(input.min, isFloat) : null;
  let max = input.hasAttribute('max') ? convertToNum(input.max, isFloat) : null;

  if (sets.min) min = sets.min;
  if (sets.max) max = sets.max;

  if (!val) {
    if (min !== null) return min;
    return 0;
  }

  if (val.match(isFloat ? /[^\d\-\.]/ : /[^\d\-]/)) {
    val = val.replace(/[^\d\-\.]/g, "");
    val = convertToNum(val, isFloat);
    if (val) {
      if (min !== null && val < min) return min;
      if (max !== null && val > max) return max;
    }
    if (min !== null) return min;
    return 0;
  }

  val = convertToNum(val, isFloat);

  if (min !== null && val < min) return min;
  if (max !== null && val > max) return max;

  if (isFloat) return roundFloat(val, isFloat);
  return val;
}



const convertToNum = (num, isFloat = false) => {
  return isFloat ? parseFloat(num) : parseInt(num);
}


const roundFloat = (num, float) => {
  num = parseFloat(num);
  float = (float - 1) * 100;
  let res = Math.round((num + Number.EPSILON) * float) / float;
  return res;
}
