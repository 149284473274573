import { dom } from "vnet-dom";



export const fixNavbar = () => {
  let nav = dom.findFirst('.fix-on-scroll');
  if (!nav) return;

  maybeFixNavbar(nav);

  dom.onWindowScroll(e => {
    maybeFixNavbar(nav);
  });

}




export const maybeFixNavbar = nav => {

  let top = nav.getBoundingClientRect().top;
  if (dom.window.innerWidth < 992) {
    dom.addClass(nav, 'is-fixed');
    return;
  }
  if (top <= 0 && !nav.classList.contains('is-fixed')) {
    dom.addClass(nav, 'is-fixed');
    dom.addClass(dom.body, 'navbar-is-fixed');
  }
  if (top > 0 && nav.classList.contains('is-fixed') && !nav.classList.contains('force-fix')) {
    dom.removeClass(nav, 'is-fixed');
    dom.removeClass(dom.body, 'navbar-is-fixed');
  }

}