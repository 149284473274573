import { dom } from "vnet-dom";



export const timeoutAlert = () => {
  let alerts = dom.findAll('.timeout-alert');
  if (!alerts) return;

  dom.window.addEventListener('load', e => {
    dom.addClass(alerts, 'visible');
    initDismiss(alerts);
  });
}





const initDismiss = alerts => {
  alerts.forEach(alert => {
    let closeBtn = dom.findFirst('.close-alert', alert);
    dom.onClick(closeBtn, e => dismissAlert(alert, e));
    setTimeout(() => dismissAlert(alert), 3000);
  });
}





const dismissAlert = (alert, e) => {
  e && e.preventDefault();
  if (!alert || alert.classList.contains('in-close')) return;
  dom.addClass(alert, 'in-close');
  setTimeout(() => {
    alert.parentNode.removeChild(alert);
  }, 300);
}

