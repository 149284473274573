import { dom } from "vnet-dom";




export const mobileFilter = () => {
  dom.onClick('.js-open-filter', e => {
    e.preventDefault();
    dom.addClass(dom.body, 'filter-is-open compensate-for-scrollbar');
  });
  dom.onClick('.js-close-filter', e => {
    e.preventDefault();
    dom.removeClass(dom.body, 'filter-is-open compensate-for-scrollbar');
  });
}