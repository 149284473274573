import { dom } from "vnet-dom";



export const removeGridClassOnBreakpoint = () => {
  dom.onWindowResize(e => {
    if (dom.window.innerWidth < 800) {
      dom.removeClass(dom.body, 'grid-row');
    }
  });
}