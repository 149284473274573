import "../../css/dev/index.scss";

import { dom } from "vnet-dom";

// DOM FUNCTIONS
// import { ajaxFancybox } from "vnet-dom/DOM/ajaxFancybox";
import { ajaxForm } from "vnet-dom/DOM/ajaxForm";
import { domFormValidate } from "vnet-dom/DOM/domFormValidate";
import { domFocusElements } from "vnet-dom/DOM/domFocusElements";
import { domTabs } from "vnet-dom/DOM/domTabs";
import { ajaxLoadOnView } from "vnet-dom/DOM/ajaxLoadOnView";

// PROJECT FUNCTIONS
import { preloader, createAlertModal } from "./components";
import { addInputError, removeInputError } from "./forms/validate";
import { clickEvents } from "./clickEvents";
import { setCompensateScrollbar } from "./setCompensateScrollbar";
import { initFormsElements } from "./forms";
import { dynamicSlick, staticSlick } from "./slick";
import { staticShopFunctions, dynamicShopFunctions } from "./shop";
import { fixNavbar } from "./fixNavbar";
import { headerCatBtn } from "./headerCatBtn";
import { validateOnBlur } from "./forms/validate";
import { collapseList } from "./collapseList";
import { ajaxModal } from "./ajaxModal";
import { filtering } from "./filtering";
import { domAccordion } from "./domAccordion";
import { overMap } from "./overMap";
import { collapse } from "./collapse";
import { mobileMenu } from "./mobileMenu";
import { mobileProfileMenu } from "./mobileProfileMenu";
import { removeGridClassOnBreakpoint } from "./removeGridClassOnBreakpoint";
import { setScrollDirection } from "./setScrollDirection";
import { timeoutAlert } from "./timeoutAlert";
import { parseServerResponse } from "./parseServerResponse";
import { dataHref } from "./dataHref";
import { stopPropagation } from "./stopPropagation";
import { appendCaptchaToken } from "./forms/validate";
import { ajaxLoadMore } from "./ajaxLoadMore";
import { alertOnLoad } from "./alertOnLoad";
import { frontSlimLogos } from "./frontSlimLogos";
import { sticky } from "./sticky";
import { sectionRecProdsHover } from "./sectionRecProdsHover";
import { singleProdGallery } from "./singleProdGallery";
import { bodyScrollLock } from "./bodyScrollLock";
import { equalHeightProductTitles } from "./equalHeightProductTitles";




/**
 *
 * @param {selector/dom/null} wrap
 * - init all function on specific container
 */
export const dynamicFunctions = wrap => {
  let container = dom.getContainer(wrap);
  if (!container) return;

  domFocusElements({ container });

  domFormValidate({ container, messages: dom.jsonParse(back_dates.validate_msg), addInputError, removeInputError, DEBUG: false, afterValidate: appendCaptchaToken });
  ajaxLoadOnView({ container, preloader });

  initFormsElements(container);
  dynamicSlick(container);
  dynamicShopFunctions(container);
  domTabs(container);
  validateOnBlur(container);
  collapseList(container);
  ajaxModal(container);
  domAccordion(container);
  overMap(container);
  collapse(container);
  stopPropagation(container);
  dataHref(container);
  ajaxLoadMore(container);
  sticky(container);
  equalHeightProductTitles(container);
}



const staticFunctions = () => {
  setCompensateScrollbar();
  clickEvents();
  ajaxForm({ preloader, createAlertModal, addInputError, parseResponse: parseServerResponse });
  staticSlick();
  staticShopFunctions();
  fixNavbar();
  headerCatBtn();
  filtering();
  mobileMenu();
  mobileProfileMenu();
  removeGridClassOnBreakpoint();
  setScrollDirection();
  timeoutAlert();
  alertOnLoad();
  frontSlimLogos();
  sectionRecProdsHover();
  singleProdGallery();
  bodyScrollLock(['compensate-for-scrollbar', 'mobile-menu-is-open']);

  dom.body.addEventListener('keyup', e => {
    if (e.keyCode === 27) {
      dom.removeClass('.dropdown.active', 'active');
      dom.removeClass('.modal.visible', 'visible');
      dom.removeClass('[data-ajax-modal]', 'active');
      dom.removeClass(dom.body, 'compensate-for-scrollbar filter-is-open profile-menu-is-open');
      dom.removeClass('.js-slim-logos .slim-logo-dropdown, .js-slim-logos .slim-logo', 'active');
    }
  });
}




staticFunctions();
dynamicFunctions();

window.initDomFunctions = dynamicFunctions;
