import { dom } from "vnet-dom";




export const collapse = container => {
  dom.onClick('.toggle-collapse', e => {
    e.preventDefault();
    toggleCollapse(e.currentTarget);
  }, container);
}



const toggleCollapse = item => {
  if (!canCollapse(item)) return;

  let target = item.dataset.target;
  if (!target) return;
  target = dom.findFirst(target);
  if (!target) return;

  if (item.classList.contains('active')) {
    dom.removeClass(item, 'active');
    $(target).slideUp({ duration: 150, complete: () => dom.removeClass(target, 'active') });
  } else {
    dom.addClass(item, 'active');
    $(target).slideDown({ duration: 150, complete: () => dom.addClass(target, 'active') });
  }
}





const canCollapse = item => {
  let max = parseInt(item.dataset.breakpointMax);
  let min = parseInt(item.dataset.breakpointMin);
  if (!max && !min) return true;
  if (max && min && window.innerWidth < max && window.innerWidth >= min) return true;
  if (max && window.innerWidth < max) return true;
  if (min && window.innerWidth >= min) return true;
  return false;
}
