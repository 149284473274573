import { dom } from "vnet-dom";

const isSafari = require('is-safari');

let LOCK_CLASSES = [];



export const bodyScrollLock = (lockClasses) => {
  if (!isSafari) return;

  LOCK_CLASSES = lockClasses;

  let observer = new MutationObserver(onBodyClassChange);

  observer.observe(dom.body, {
    attributes: true,
    attributeFilter: ['class'],
    childList: false,
    characterData: false
  });
}




const onBodyClassChange = e => {
  if (hasBodyLockClass()) {
    lockBody();
  } else {
    unlockBody();
  }
}






const lockBody = () => {
  if (dom.body.hasAttribute('data-locked')) return;
  dom.body.setAttribute('data-locked', 'true');
  let scroll = dom.window.pageYOffset;
  dom.body.dataset.scroll = scroll;
  dom.css(dom.body, {
    overflow: 'hidden',
    position: 'fixed',
    top: '0',
    height: '100%',
    width: '100%'
  });
  dom.body.scrollTo(0, scroll);
}



const unlockBody = () => {
  if (!dom.body.hasAttribute('data-locked')) return;
  dom.body.removeAttribute('data-locked');
  dom.body.removeAttribute('style');
  if (dom.body.hasAttribute('data-scroll')) {
    dom.window.scrollTo(0, parseFloat(dom.body.dataset.scroll));
  }
}





const hasBodyLockClass = () => {
  for (let i = 0; i < dom.body.classList.length; i++) {
    let className = dom.body.classList[i];
    if (LOCK_CLASSES.includes(className)) {
      return true;
    }
  }
  return false;
}



