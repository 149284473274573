import { dom } from "vnet-dom";

const Sticky = require('sticky-js');



export const sticky = container => {
  let obj = new Sticky('.js-sticky');
}


