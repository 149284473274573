import { dom } from "vnet-dom";



export const domAccordion = container => {
  let items = dom.findAll('.dom-accordion', container);
  if (!items) return;
  items.forEach(item => init(item));
}



const init = accordion => {
  dom.onClick('.accordion-head', e => {
    e.preventDefault();
    let head = e.currentTarget;
    let wrap = head.parentNode;
    if (wrap.classList.contains('active')) {
      hideItem(wrap);
    } else {
      displayItem(wrap, accordion);
    }
  }, accordion);
}



const hideItem = wrap => {
  if (!wrap) return;
  let body = dom.findFirst('.accordion-body', wrap);
  $(body).slideUp({ duration: 150 });
  dom.removeClass(wrap, 'active');
}


const displayItem = (wrap, accordion) => {
  if (!wrap) return;
  let currentActive = dom.findFirst('.accordion-item.active', accordion);
  hideItem(currentActive);
  let body = dom.findFirst('.accordion-body', wrap);
  $(body).slideDown({ duration: 150 });
  dom.addClass(wrap, 'active');
}

