import { dom } from "vnet-dom";

import { rangeInput } from "./rangeInput";
import { inputNumber } from "./inputNumber";
import { niceSelect } from "./niceSelect";
import { inputPhone } from "./inputPhone";
import { showPassword } from "./showPassword";
import { resetForm } from "./resetForm";
import { fileInput } from "./fileInput";


export const initFormsElements = container => {
  let inputs = dom.findAll('.input', container);
  if (inputs && inputs.length) {
    setClassValue(inputs);
  }

  rangeInput(container);
  inputNumber(container);
  niceSelect(container);
  inputPhone(container);
  showPassword(container);
  resetForm(container);
  fileInput(container);
}





const setClassValue = inputs => {
  inputs.forEach(input => {
    input.addEventListener('change', e => {
      if (input.value) {
        dom.addClass(input, 'has-value');
      } else {
        dom.removeClass(input, 'has-value');
      }
    });
  });
}





