import { dom } from "vnet-dom";
import { maybeFixNavbar } from "./fixNavbar";



export const headerCatBtn = () => {

  let btn = dom.findFirst('.header .catalog-btn');
  if (!btn) return;
  let fixBar = dom.findFirst('.fix-on-scroll');
  let catMenu = dom.findFirst('.catalog-menu');

  dom.onClick(btn, e => {
    e.preventDefault();
    if (btn.classList.contains('active')) {
      closeCatalog(btn, fixBar, catMenu);
    } else {
      openCatalog(btn, fixBar, catMenu);
    }
  });

  dom.body.addEventListener('keyup', e => {
    let code = e.keyCode;
    if (code === 27) closeCatalog(btn, fixBar, catMenu);
  });
}


const closeCatalog = (btn, fixBar, catMenu) => {
  dom.removeClass(btn, 'active');
  dom.removeClass(dom.findFirst('.hamburger', btn), 'is-active');
  dom.removeClass(fixBar, 'force-fix');
  dom.removeClass(catMenu, 'active');
  maybeFixNavbar(fixBar);
}



const openCatalog = (btn, fixBar, catMenu) => {
  dom.addClass(btn, 'active');
  dom.addClass(dom.findFirst('.hamburger', btn), 'is-active');
  dom.addClass(fixBar, 'is-fixed force-fix');
  dom.addClass(catMenu, 'active');
}