import { dom } from "vnet-dom";

export const inputPhone = container => {
  let inputs = dom.findAll('input[data-phone]', container);
  if (!inputs || !inputs.length) return;

  inputs.forEach(input => {
    $(input).mask(input.dataset.phone, {
      completed: () => {
        dom.addClass(input, 'has-value');
      },
    });
    input.addEventListener('blur', e => {
      if (!input.value) dom.removeClass(input, 'has-value');
    })
  });
}