import { dom } from "vnet-dom";





export const mobileProfileMenu = () => {
  dom.onClick('.js-open-mob-profile-menu', e => {
    e.preventDefault();
    if (dom.body.classList.contains('profile-menu-is-open')) {
      closeMenu();
    } else {
      openMenu();
    }
  });
  dom.onClick('.mobile-profile-menu .menu-content', e => {
    e.stopPropagation();
  });
  dom.onClick('.mobile-profile-menu', e => {
    closeMenu();
  });
  dom.onClick('.mobile-profile-menu .close-menu', e => {
    e.preventDefault();
    closeMenu();
  });
}




const closeMenu = () => {
  dom.removeClass(dom.body, 'profile-menu-is-open compensate-for-scrollbar');
}



const openMenu = () => {
  dom.removeClass(dom.body, 'mobile-menu-is-open');
  dom.addClass(dom.body, 'profile-menu-is-open compensate-for-scrollbar');
}