import { dom } from "vnet-dom";
import { domFocusElements } from "vnet-dom/DOM/domFocusElements";



export const collapseList = container => {
  let btns = dom.findAll('.js-collapse-list', container);
  if (!btns || !btns.length) return;
  btns.forEach(btn => {
    init(btn);
  });
}




const init = btn => {
  let items = getListItems(btn);
  if (!items) return;

  showMinimal(items, btn);

  let collapseItems = items.filter(item => !item.classList.contains('active'));
  if (!collapseItems || !collapseItems.length) {
    dom.addClass(btn, 'disabled');
  }

  btn.addEventListener('click', e => {
    e.preventDefault();
    if (!collapseItems || !collapseItems.length) return;
    toggleCollapse(collapseItems, btn);
  });
}




const getListItems = btn => {
  return dom.findAll(btn.dataset.target);
}


const showMinimal = (items, btn) => {
  let min = parseInt(btn.dataset.min);
  min = min < items.length ? min : items.length;
  for (let i = 0; i < min; i++) {
    dom.addClass(items[i], 'active');
  }
}


const toggleCollapse = (items, btn) => {
  if (!btn.classList.contains('active')) {
    showItems(items, btn);
  } else {
    collapseItems(items, btn);
  }
}



const showItems = (items, btn) => {
  dom.addClass(btn, 'active');
  dom.addClass(items, 'active');
}



const collapseItems = (items, btn) => {
  dom.removeClass(btn, 'active');
  dom.removeClass(items, 'active');
}
