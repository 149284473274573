/**
 * - Добавляем z-index при наведении на сарточку товара в блоке section-recommended_prods
 * - Страница: корзина
 */

import { dom } from "vnet-dom";



export const sectionRecProdsHover = () => {
  let section = dom.findFirst('.section-recommended_prods');
  if (!section) return;

  let cards = dom.findAll('.prod-loop-card', section);

  let wrapper = dom.findFirst('.slick-slider', section);

  if (!cards || !wrapper) return;

  cards.forEach(card => {
    card.addEventListener('mouseenter', e => {
      dom.addClass(wrapper, 'has-hover');
    });
    card.addEventListener('mouseleave', e => {
      dom.removeClass(wrapper, 'has-hover');
    });
  });
}