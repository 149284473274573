import { dom } from "vnet-dom";

export const equalHeightProductTitles = container => {
    let sections = dom.findAll('.prods-count-slider', container);
    if (!sections || !sections.length) return;
    sections.forEach(section => {
      let titles = dom.findAll('.prod-name', section);
      let maxHeight = 0;
      titles.forEach(title => {
          $(title).height('');
          maxHeight = Math.max($(title).outerHeight(), maxHeight);
      });
      titles.forEach(title => {
          $(title).height(maxHeight);
      });
    });
}

let equalHeightProductTitlesTimeout;
dom.onWindowResize(e => {
    clearTimeout(equalHeightProductTitlesTimeout);
    equalHeightProductTitlesTimeout = setTimeout(function(){
        equalHeightProductTitles();
    }, 250);
});
